body {
    margin: 0;
    word-wrap: break-word;
}
/* ============ Portrait ============ */
.main-image {
    width: 125px;
    height: 125px;
    margin: 20vh auto 0 auto;

        background-image: url('../../public/assets/images/icon.png');

    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}
#content-container {
    box-sizing:border-box;
    padding: 24px;
    margin:0 24px 0 24px;
    background-color: #ffffff;
}
.app-title {
    font-size: 14px;
    text-align: center;
    padding-bottom: 11px;
    text-transform: uppercase;
}
.input-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width:100%;
    height:110px !important;
    background-color: white;
    -webkit-box-shadow: 0px -20px 20px -8px rgba(255,255,255,1);
    -moz-box-shadow: 0px -20px 20px -8px rgba(255,255,255,1);
    box-shadow: 0px -20px 20px -8px rgba(255,255,255,1);
}
.input-container {
    width: 100%;
    padding:0 24px;
    text-align:center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.form-get-the-app {
    text-align:center;
    padding-top: 10px;
}
.form-get-the-app > input {
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 2px;
    font-size: 60px;
    color: #CECECE;
    line-height: 24px;
    padding-left: 20px;
}
.cta-button {
    display: block;
    height: 62px;
    line-height:62px;
    background-color: #06122F;
    border-radius: 2px;
    font-size: 20px;
    padding:0 20px;
    margin: 0 20px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
}
/* No data (portrait) */
@media only screen and (orientation: portrait) {
    .card--no-data .main-image {

        background-image: url('../../public/assets/images/icon.png');
    }
}
/* ============ Landscape ============ */
@media only screen and (orientation: landscape){
    .main-image {
        margin: 30px auto 0 auto;
    }
    #content-container {
      padding: 10px 24px;
    }
    .input-wrapper {
      height: 80px !important;
    }
    .input-container {
      width: 50%;
      margin: 0 auto;
    }
    .form-get-the-app {
        padding-top:8px;
    }
    .cta-button {
        height: 44px;
        line-height: 44px;
    }
}
/* Reusables */
.text-bold {
    font-family: "Open Sans", Helvetica, serif;
    font-weight: 700
}
.text-light {
    font-family: "Open Sans", Helvetica, serif;
    font-weight: 400;
}